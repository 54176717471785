


























export default {
  props: ['details'],

  data(): any {
    return {
      //@ts-ignore
      image: this.details.bg_icon ? require('../../assets/' + this.details.bg_icon) : null
    }
  }
}
