




























































import { Component, Vue } from 'vue-property-decorator';
import AnimatedName from '@/components/AnimtedName.vue';
import Projects from '@/components/Projects.vue';
import Experience from '@/components/Experience.vue';

@Component({
  components: {
    Projects,
    AnimatedName,
    Experience
  },
})
export default class App extends Vue {
  tabs = [
    'Projects',
    'Experience (WIP)'
  ]

  selected = this.tabs[0];
  headerOnTop = false;

  handleTabClick(tab: string) {
    this.selected = tab;
  }

  open(url: string) {
    window.open(url, '_blank')
  }

  isSelected(tab: string) {
    return this.selected === tab;
  }

  showMore() {
    document.getElementById('rightContent')?.scrollIntoView({ behavior: 'smooth' });
  }
}
