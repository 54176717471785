<template>
  <div class="experience-container grow">
    <br/>
    
    <h4> Coming soon! </h4>
  </div>
</template>

<script>
export default {
  data() {
    return { };
  }
}
</script>

<style scoped>
.experience-container {
  padding: 25px;
}
</style>